$grey: #777;
$grey2: #dfe3e4;
$blue: #2183dd;
$green: #009900;
$white: #fff;

.mg50 {
  margin: 50px;
}

.progressBarAc {
  width: 100%;
  background-color: gray;

  .progressBarLi {
    border: 2px solid $red2;
    transition: all 1s;
  }
}


.progressBarBc {
  width: 100%;
  position: relative;

  .progressActiveAc {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    transition: all 0.5s;
  }

  .progressActiveBc {
    position: absolute;
    text-align: center;
    width: 140px;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    transition: all 0.5s;
  }
}