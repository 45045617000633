.dx-toolbar {

  padding: 5px 10px;
}
.dx-button:not(.dx-visibility-change-handler .dx-button) {
  background-color: $white;
  border: none;
}

.login-container-admin{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 0;
  height: 80vh;
}

.content-form-admin{
  width: 100%;
  height: 100%;
  padding: 15px 48px;
  position: relative;
  z-index: 4;
  background: rgba(255,255,255,0.9);
}

.login-triangle-1{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-radius: 5px;
  border-color: #13406f transparent transparent transparent;
  z-index: 1;
  position: absolute;
  left: -10px;
  bottom: -10px;
}

.login-triangle-4{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 100px 100px 0 0;
  border-radius: 5px;
  border-color: darkred transparent transparent transparent;
  z-index: 1;
  position: absolute;
  left: 310px;
  bottom: 210px;
}

.login-triangle-2{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 205px 205px;
  border-color: transparent transparent #007bff transparent;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.login-triangle-6{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 205px 205px 0 0 ;
  border-color: #007bff transparent transparent  transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
}

.login-triangle-3{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 500px 700px;
  border-color: transparent transparent #13406f transparent;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.login-triangle-5{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 500px 700px 0 0 ;
  border-color: #13406f transparent transparent transparent;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2;
}

.login-triangle-7{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 600px 1000px 0 0 ;
  border-color: cornflowerblue transparent transparent transparent;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.login-bound-admin {
  z-index: 1000;
  display: flex;
  width: 450px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.35);
  position: absolute;
}

.login-form-admin{

}

.header-admin {
  .view-logo {
    .logo {
      .logo-divider {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.logo-admin{
  position: absolute;
  bottom: 0;
  right: 0;
}

.lb-title-admin {
  font-size: 18px;
  color: #000000;
  margin-bottom: 5px;
}

.label-above-admin {
  text-align: left !important;
  line-height: 14px;
  font-weight: 200;
}

.water-mark-admin{
  position: absolute;
  font-size: 350px;
  color: #ffffff;
  opacity: 0.7;
  //left: -40px;
  letter-spacing: -45px;
  bottom: 0px;
  line-height: 292px;
  text-shadow: 0px 1px 3px $primaryColor;
  pointer-events: none;
}

.logo-adminhr {
  position: relative;
  left: -30px;
}

#content-admin {
  height:900px ;
  padding: 10px 20px;
  background: whitesmoke!important;

}

#content-admin h2 {
  font-size: 26px;
}

.panel-admin{
  box-shadow: rgba(60, 64, 67, 3) 0 1px 2px 0;
  padding: 10px 10px 1px 10px;
  border-radius: 5px;
  background: white;
}

.page-container-admin{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-logo {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 15px 15px 31px 15px;
  &.solid {
    border: 1px solid #707070;
  }
  &.dashed {
    z-index: 2000;
    background-color: #eaeaeabf;
    border: 1px dashed #707070;
  }
}

//@media only screen and (min-width: 900px) {
//  .page-container-admin{
//    margin: 0 100px 0 100px;
//  }
//}
//
//@media only screen and (min-width: 1400px) {
//  .page-container-admin{
//    margin: 0 300px 0 200px;
//  }
//}
//
//@media only screen and (min-width: 1700px) {
//  .page-container-admin{
//    margin: 0 700px 0 200px;
//  }
//}

@media (max-width: 720px) {
  .header-admin {
    .view-logo {
      .logo {
        .logo-divider {
          display: none;
        }
      }
    }
  }
}


.menu-admin {
  height: 900px;
  border: none!important;
  .dx-list-item.dx-item {
    height: 47px !important;
    line-height: 42px !important;
    font-size: 16px !important;
    background: #2A3F54 !important;
    color: #fff;
    border: none;
  }
  .dx-list-item-icon-container, .dx-toolbar-before {
    width: 36px;
    padding-right: 0px !important;
    text-align: center;
  }
  .dx-list-item.dx-item:hover {
    background: #ffc107 !important;
  }
  .dx-scrollable-content{
    background: #2A3F54 !important;
  }
  .dx-icon.dx-list-item-icon{
    color: #fff!important;
  }
  .dx-list-item-content {
    padding: 0 0 0 0 !important;
    white-space: normal;
    border: none;
  }
  .dx-icon.dx-list-item-icon{
    color: #fff;
  }
}
