
#user-profile-component {
  #user-profile-title {
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
    color: #ff00008f;
    text-decoration-line: underline;
    margin-top: 10px;
  }

  #search-user-profile {
    border-radius: 22px;
  }
  .user-profile-content:hover{
    cursor: pointer;
  }

  #search-button-user-profile {
    color: white;
    background-color: rgb(0, 148, 236);
    position: absolute;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    z-index: 10;
    right: 0;
    padding: 10px 0 0 0;
    height: 37px;
    bottom: 0;
    width: 37px;
  }
.user-profile-content{
  padding:0 20px 0 20px
}
  .user-profile-detail:hover{
    box-shadow: rgba(226, 127, 127, 0.33) -3px 3px 0 0
  }
  .user-profile-detail{
    border: 1px solid rgba(169, 159, 159, 0.54);
    border-top: none;
    border-right: none;
    padding: 5px 0 12px 10px;
    border-radius: 9px;
    box-shadow: rgba(53, 168, 236, 0.32) -3px 3px 0 0;
    display: flex;
    align-items: flex-start;
  }
  .user-profile-image{
    margin-right: 10px !important;
    border-radius: 15%;
    img{
      object-fit:cover;
      border-radius: 5px ;
    }
  }
  .user-profile-info{
    left:70px;
  }
  .user-profile-name{
    font-size: 18px;
    font-weight: 600;
  }
  .user-profile-extinfo{
    color: rgba(82, 78, 78, 0.65);
    font-weight:500
  }
}
