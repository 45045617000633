@import "color";
@import "font";
@import "sf-font";
@import "general";
@import "header";
@import "footer";
@import "side-nav";
@import "admin";
@import "dash-board";
@import "custom-devextreme-material-theme";
@import "data-grid";
@import "error";
@import "error500";
@import "image-type";
@import "list";
//@import "menu";
@import "multi-toggle";
@import "react-tags";
@import "step-progress-bar";
@import "toolbar";
@import "user-container";
@import "user-profile";
@import "loading";
@import "login";
@import "material-ui";
@import "custom";
@import "spacing";

html, .adminRoot, .body, #app div {
  max-height: 100vh;
  font-size: $fontSize;
  //letter-spacing: 0.4px;
}

@media (max-width: 1281px) {
  html, .adminRoot, .body, #app div {
    font-size: $fontSize;
  }
}

@media print {
  html, body {
    margin: 0;
  }
}

body, #root, .body-container {
  min-height: 100vh;
}

body {
  color: $textColor;
  margin: 0;
  padding: 0 !important;
  font-weight: 400;
  font-size: $fontSize;
  font-family: $fontDefault, "Font Awesome 5 Free",sans-serif;
  overflow: auto;
}

a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}

*:focus {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

@-webkit-keyframes autofill {
  to {
    color: #59617B;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.display_row {
  display: flex;
}

.display_col {
  display: flex;
  flex-direction: column;
}

.flex-wrap{
  flex-wrap: wrap;
}

.align-left {
  align-items: flex-start;
}

.align-right {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-between {
  justify-content: space-between;
}

.valign-middle {
  justify-content: center;
}

.valign-top {
  justify-content: flex-start;
}

.valign-bottom {
  justify-content: flex-end;
}

.valign-around {
  justify-content: space-around;
}

a {
  cursor: pointer;
}

.pos_fixed {
  position: fixed !important;
}

.full_w {
  width: 100% !important;
}

.popup-modal {
  width: 100% !important;
}

.btn-close {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.btn-close:hover {
  opacity: 0.7;
}

.error {
  color: red;
}

.success {
  color: #A2DDB7;
}

.text-purple {
  color: green;
}

.modal {
  overflow: auto !important;

  .modal-dialog {
    margin: 30px auto;
  }
}

#frmW18F1031 {
  .tab-content {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    padding: 5px 15px;
  }
}

.form-input {
  width: 100%;
  padding-right: 30px;
  position: relative;
}

.form-control-feedback {
  right: 20px !important;
  bottom: 0 !important;
  top: auto;
}

.promotion .form-control-feedback {
  right: -3px !important;
}

.has-feedback .form-control {
  padding-right: 10px !important;
}

.them {
  right: 10px !important;
}

.table-new {
  color: #333;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 0 10px;
  border-radius: 5px;
  align-items: center;
  display: flex;
}

.table-image {
  height: 70px;
  object-fit: cover;
  margin-right: 5px;
  margin-bottom: 5px;
}

.table-video {
  width: 70px;
  height: 70px;
  object-fit: contain;
  background-color: black;
}

.text-area {
  height: 200px !important;
}

.table-btn-remove {
  color: #333;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 0;
  width: 75px;
  margin: 0 2px;
  border-radius: 3px;
}

.btn-group > .btn {
  border-radius: 0;
}

#branchSort, #bSortType {
  padding: 1px 12px;
}

textarea {
  resize: vertical;
}

.wrap {
  flex-wrap: wrap;
  display: flex;
}

.form-row {
  margin-right: 30px;
  width: 20%;
}

.form-row-2 {
  margin-right: 30px;
  width: 40%;
}

.bold {
  font-weight: bold;
}

.loading {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #d4d4d466;
  z-index: 100;
}

.tooltip [class$="inner"] {
  background-color: #d6d6d6;
  border: 1px solid #d6d6d6;
  color: #2f78b9;
}

.tooltip [class$="arrow"] {
  border-bottom: 5px solid #d6d6d6 !important;
}

.flag-focus {
  border-bottom: 1px solid;
}

.header-left {
  flex-direction: row;
  display: flex !important;
  justify-content: flex-start !important;
}

.header-right {
  display: flex;
  justify-content: flex-end !important;
}

.text-red {
  color: red;
}

.text-warnings {
  color: #ffc107;
}

.clr-cus {
  color: #337ab7 !important;
}

.pdr5 {
  padding-right: 5px !important;
}

.pdl10 {
  padding-left: 10px !important;
}

.hide {
  display: none;
}

.avatar {
  position: relative;
  display: inline-block;
}

.img-avatar, .avatar > img, .img-circle {
  max-width: 100%;
  width: 40px !important;
  height: 40px;
  border-radius: 50%;
  object-position: top !important;
  object-fit: cover !important;
}

.display-menusidebar {
  display: flex;
}

.display-menutop {
  flex-direction: row;
}

@media only screen and (max-width: 1070px) {
  .menu {
    display: none;
  }
  .side-container{
    .btn-top-menu{
      display: block;
    }
  }
}

@media only screen and (min-width: 1070px) {
  .menu {
    display: flex;
  }
}

@media only screen and (max-width: 992px) {
  .group-menu-ess{
    //display: none;
  }
  .display-menusidebar {
    display: flex;
  }

  //.side-container .menu-top {
  //  display: block;
  //}
}

@media only screen and (min-width: 992px) {
  .display-menusidebar {
    display: none;
  }

  //.side-container .menu-top{
  //  display: none;
  //}
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
}

.fontsize {
  font-size: 15px !important;
}

.dropdown-header {
  position: relative !important;
  text-align: center !important;
  min-width: 180px !important;
  width: auto;
  padding: 10px 9px !important;
  font-size: 12px !important;
  background-color: #F0F3F5;
  white-space: nowrap !important;
}

.dropdown-body {
  position: relative;
  width: auto;
  max-height: 500px;
  overflow: auto;

  li {
    border-bottom: 1px solid #e3e6e8 !important;
    padding: 5px 10px !important;
    display: flex;
    flex-direction: column;
  }
}

.dropdown-footer {
  position: relative;
  width: auto;
  font-size: 12px !important;
  padding: 10px 9px;
  margin-top: 0 !important;
  color: #F0F3F5;
  text-decoration: underline;
  border-top: 1px solid #dddfe2;
}

.total-notify {
  //color: $crmBackGroundColorMain !important;
  text-align: left !important;
  padding: 6px 9px !important;
  background-color: #e3e6e8 !important;
  border-bottom: 1px solid lightgrey;
}

.div-notify {
  z-index: 1000;
  width: 250px;
  position: absolute;
  top: 46px;
  right: -40px;
  background-color: white;
  box-shadow: 0 3px 5px 1px #cdcdcd;
  padding: 0;
  border-radius: 3px;

  .text-no-data {
    line-height: 100px;
    text-align: center;
    display: block;
    width: 100%;
  }

  .text-content {
    display: block;
    display: -webkit-box;
    width: 100%;
    max-width: 100%;
    max-height: 32px;
    font-size: 12px;
    margin: 0 auto;
    line-height: 16px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    b {
      //color: $crmBackGroundColorMain;
    }
  }

  .item-notify {
    border-bottom: 1px solid #dddfe2;
    padding: 15px 0 10px 0;
    cursor: pointer;
    flex-direction: row !important;

    .avatar-sender {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      object-fit: cover;
      margin-right: 10px
    }
  }

  .text-truncate {
    font-size: 9px;
    color: grey;
    text-align: right;
  }
}

.dropdown-change {
  width: auto !important;
  position: relative;
  max-width: 250px !important;
  font-size: 12px !important;
  background-color: #ffffff !important;
  border: 1px solid #c8ced3 !important;
  border-radius: 5px !important;
  overflow: hidden;
  box-shadow: 0 3px 5px 1px #cdcdcd;
}

fieldset {
  border-radius: 0;
}

.header-name {
  padding: 5px 0;
  align-items: center;
  display: flex;
  text-align: right;

  span {
    max-width: 100px;
    overflow: hidden;
    max-height: 20px;
    line-height: 15px;
    text-overflow: ellipsis;
    margin: 3px 0;
  }
}

.header-name:hover, .img-avatar:hover {
  transition: all 0.5s;
  transform: scale(1.1);
}

.header-logo {
  height: 35px;
  object-fit: cover;
  margin-top: 7px;
  margin-right: 5px;
}

.main {
  width: 100%;
}

.login_title {
  font-size: 35px !important;
  color: #0074d9 !important;
}

.login {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.register_error {
  color: red;
}

.login_btn_login {
  width: 100%;
  color: white !important;
  background-image: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: #26953a !important;
  margin-top: 15px;
  text-shadow: none !important;
  font-weight: bold;
  position: relative;

  .ico_face {
    font-size: 22px;
    position: absolute;
    left: 10px;
  }
}

.select {
  -webkit-touch-callout: default;
  -webkit-user-select: auto;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.fu-label {
  display: flex;
  width: 100%;
  color: #8e8e8e;
  margin-bottom: 5px;
}

.cf-feedback {
  display: flex;
  height: 1px;
  position: relative;
}

.cf-error {
  color: red;
  width: 100%;
  display: flex;
}

.opt-left {
  .form-group label {
    margin-left: 20px !important;
  }
}

.tff-1 {
  width: 15%;
  max-width: 120px;
}

.tff-2 {
  width: 51%;
}

.tff-3 {
  width: 13%;
}

.tff-4 {
  width: 13%;
  max-width: 100px;
}

.fu-val {
  .tff-3 {
    width: 20%;
  }
}

.page-container {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.field-search {
}

.lb-search {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  margin-top: 3px;
  margin-right: 5px;
}

.lb-cus-title {
  font-size: 19px;
  margin: 10px 0;
}

.lb-nomal {
  font-size: 14px;
  margin: 10px 0;
}

.toolbar {
  justify-content: space-between;
}

.text-blue {
  color: $default !important;
}

.text-black {
  color: $black !important;
}

.text-yellow {
  color: $yellow !important;
}

.action-button {
  margin: 0 5px;
}

.action-view-link:hover i,
.action-edit-link:hover i {
  color: #2f78b9 !important;
  cursor: pointer;
}

.action-view-link i,
.action-edit-link i {
  color: #f39c12 !important;
}

.action-del-link:hover i {
  color: #2f78b9 !important;
  cursor: pointer;
}

.action-del-link i {
  color: red !important;
}

.action-his-link:hover i {
  color: #2f78b9 !important;
  cursor: pointer;
}

.action-his-link i {
  color: #6b6b72 !important;
}

.color-add {
  color: #015f97 !important;

}

.control-label {
  text-align: left !important;
}

.control-label-above {
  text-align: left !important;
  line-height: 31px;
}

.btn {
  border: 1px solid #ced4da !important;
}

.btn .caret {
  display: none;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.mgr5 {
  margin-right: 5px !important;
}

.mgr10 {
  margin-right: 10px !important;
}

.mgr20 {
  margin-right: 20px !important;
}

.mgt15 {
  margin-top: 15px !important;
}

.mgl10 {
  margin-left: 10px !important;
}

.mgb5 {
  margin-bottom: 5px !important;
}

.mgb10 {
  margin-bottom: 10px !important;
}

.mg25 {
  margin-left: -20px !important;
}

.mg0 {
  margin: 0 !important;
}
.pd0 {
  padding: 0 !important;
}
.pd5 {
  padding: 5px !important;
}

.pd10 {
  padding: 10px !important;
}

.pdh10 {
  padding: 0 10px !important;
}
.pdv10 {
  padding: 10px 0 !important;
}

.pd10 {
  padding: 10px !important;
}

.pdt10 {
  padding-top: 10px !important;
}

.pdt5 {
  padding-top: 5px !important;
}

.card-account {
  border: 1px solid #e3e3e3 !important;
}

.mgl-12 {
  margin-left: -12px !important;
}

.account-img {
  display: block;
  width: 60px;
  max-height: 60px;
}

.new-account-img {
  display: block;
  width: 200px;
  max-height: 200px;
  border-radius: 50%;
}

.jus-between {
  display: flex;
  justify-content: space-between;
}

.jus-end {
  display: flex;
  justify-content: flex-end;
}

.tab-content > .active {
  display: contents !important;
}

.textarea {
  height: 150px;
}

.card {
  margin-bottom: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: .25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 15px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  border-radius: .25rem;
}

.rotateY180 {
  transform: rotateY(180deg);
}

.input-devextreme {
  border: 1px solid #968686;
  border-radius: 4px;
  padding-left: 10px;
}

.mgt5 {
  margin-top: 5px;
}

.mgl5 {
  margin-left: 5px;
}

.text-green {
  color: #2a972a;
}

.headerList {
  height: 40px;
  color: #fbfbfb;
  font-size: 16px;
  line-height: 40px;
  border-radius: 3px 3px 0 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editorClassName {
  min-height: 300px;
  border: 1px solid #f3f0f0;
}

.item-attachtment {
  border: 1px solid #e4d6d6;
  border-radius: 3px;
  padding: 3px;
  background-color: rgba(255, 255, 255, 0.15);
  font-size: smaller;
  font-weight: 100;
  color: #999090;
}

.text-bold {
  font-weight: bold;
}

.text-light-blue {
  color: #00b2f0 !important;
}

.isDisabled {
  color: gray;
  cursor: not-allowed !important;
  opacity: 0.5;
  text-decoration: none;
}

.image-sale-person {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  object-fit: cover;
}

.text-orange {
  color: orange !important;
}

#hamburgerContent {
  border-radius: 0;

  button {
    text-align: left;
    width: 100%;
  }

  .popover-content {
    padding: 5px 2px;

  }

}

#hamburgerContent.popover {
  z-index: 100 !important;
}

#changePass {
  input {
    border-top: 0 white !important;
    border-left: 0 white !important;
    border-right: 0 white !important;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.075);
    border-top: 2px solid #ccc;
    border-radius: 0 !important;
    padding: 2px 2px !important;
    font-size: 30px;
    color: black;
  }

  .labelChange {
    font-size: 12px;
  }

  #passwordTitle {
    font-weight: 600;
    text-align: center;
  }

  #passwordRequired {
    position: absolute;
    left: 20%;
    font-size: 13px;
  }

  .recomended-text {
    color: #fbb99e;
  }

  .underLine {
    text-decoration: line-through;
    text-decoration-color: #CCCCCC;
  }

  .buttonChangePass {
    width: 100%;
    background-color: #5f9ed2f7;
    color: #ffffff;
    font-weight: 600
  }
}

.changePassCss {
  .title-bar {
    border-bottom: none;
  }
}

#scheduleTemplate {
  .dx-group-row {
    color: rgba(12, 5, 5, 0.33);
  }

  .dx-group-row td[role=gridcell] {
    background-color: #f7f7f7;

  }

  .dx-datagrid-expand {
    background: #f7f7f7;
  }

  .dx-header-row td {
    background-color: #337ab7 !important;
    color: white !important;

    .dx-datagrid-text-content {
      color: white !important;
    }
  }

}

#dashboard {
  @media only screen and (max-width: 1199px) {
    #dashboardButton {
      float: left;
    }
  }

  #dashboardButton {
    text-align: right;

    #user-contain {
      overflow: hidden;
      float: right;
      position: relative;
      top: -4px;

      a.fa-user-circle {
        display: block;
      }
    }
  }

  .selectedButton {
    background: #337ab7;
    color: white;
  }

  .timeSchedule {
    font-weight: 600;
  }
}

.mgb0 {
  margin-bottom: 0 !important;
}

.mgt0 {
  margin-top: 0 !important;
}

.mgl0 {
  margin-left: 0 !important;
}

.mgr0 {
  margin-right: 0 !important;
}

.mgl25 {
  margin-left: 25px !important;
}

.mgr25 {
  margin-right: 25px !important;
}

.mgt25 {
  margin-top: 25px !important;
}

.mgb25 {
  margin-bottom: 25px !important;
}

.text-no-data{
  width: 100%;
  text-align: center;
  display: block;
  color: lightgray;
}

.height-textarea {
  min-height: 140px !important;
}

.fixed-height {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mgt10 {
  margin-top: 10px !important;
}

.overflow-one-line {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

/* total width */
::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
  height: 5px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 100px;
  border: 4px solid darkgrey;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none
}

.text-darkcyan {
  color: darkcyan;
}

.fc-button-primary {
  color: #337ab7;
  background-color: white;
  border-color: #337ab7;
  outline: none !important;

  &:hover {
    color: white;
    background-color: #337ab7;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.fc-left {
  color: gray;
  //border: 1px solid gray;
  padding: 5px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc-day-header {
  height: 40px;
  line-height: 40px;
  background-color: aliceblue;
  color: gray;
}

.tox-notifications-container {
  display: none !important;
}

.view-attachment{
  position: relative;
  margin: 15px;
  max-width: 70px;
  img{
    max-width: 50px;
    max-height: 70px;
    width: 70px;
    height: 70px;
    object-fit: contain;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .img-background{
    max-width: 70px;
    object-fit: cover;
    background-color: lightgray;
  }
  .deleteAttach{
    position: absolute;
    top: -8px;
    right: -8px;
    border-radius: 70px;
    background-color: whitesmoke;
    box-shadow: 0px 0px 7px 1px lightgrey;
    overflow: hidden;
    padding: 5px;
  }
}

.view-loading{
  max-width: 70px;
  max-height: 70px;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background-color: lightgray;
  text-align: center;
  line-height: 70px;
  margin: 15px;
}

.list-attach{
  border:1px solid lightgrey;
  border-radius:4px;
  padding: 15px;
  flex-wrap:wrap;
  display: flex;
  label{
    word-break: break-all;
    display: flex;
    flex-direction: column;
  }
}

.block-info-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 75px;
  margin-bottom: 20px;
}

.block-contact-item {
  padding: 24px 84px;
  background-color: #fff;
}

.rhombus-img {
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primaryColor;
  border-radius: 30%;
  transform: rotate(45deg) scale(0.8);
}

.rhombus-img img {
  transform: rotate(-45deg);
}

.w09f200_avatar {
  max-width: 280px;

  .img-center-reponsive {
    width: 220px !important;
    height: 220px !important;
    border: 14px solid $primaryColor;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 100%;

    }
    svg, img {
      width: 80%;
      height: 100%;
      max-width: 100%;

      path {
        fill: $fontColorDefault;
      }
    }
  }
}

.dx-popup-dropdown-personal {
  padding: 0 !important;
  .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td .dx-texteditor,
  .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content) .dx-texteditor,
  .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content) .dx-texteditor {
    padding-left: 10px;
    padding-right: 10px;
  }
  .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.row-header {
  min-height: 33px;
}

.card-header {
  text-transform: uppercase;
  color: #000;
  font-weight: bold !important;
  font-family: $fontDefault, 'Font Awesome 5 Free',sans-serif !important;

  &:before {
    content: "•";
    margin-right: 12px;
    font-size: 22px;
    color: $primaryColor;
  }
}

.row-form {
  padding-left: 10px;
  border-left: 2px solid $primaryColor;
}

.hover-employee {
  background: lightgrey;
  border-radius: 5px;
  position: absolute;
  //left: 0;
  color: black;
  padding: 10px;
  margin-top: 5px;
  z-index:2;
  &:after{
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 4px solid #fff;
    border-right: 4px solid #fff;
    border-bottom: 6px solid #d3d3d3;
    position: absolute;
    left: 38px;
    top: -6px;
  }
}

.popover-userinfo {
  background: #000000 !important;
  box-shadow: 0 2px 2px #777 !important;
  color: #FFF;
  padding: 10px;
  margin-top: 5px;
  .dx-popover-arrow:after {
    background: #000000 !important;
  }
}

.switch-full-date{
  margin-bottom: 8px;
  &>.react-switch-bg {
    font-size: 12px;
    color: white;

    &:after {
      content: '1';
      position: absolute;
      right: 10px;
      top: 7px;
    }

    &:before {
      content: '0.5';
      position: absolute;
      left: 2px;
      top: 7px;
    }
  }
}

@media (max-width: 720px) {
  html, .adminRoot, .body, #app div {
    font-size: 12px;
  }
}

@media (max-width: 992px) {
  .info-view,.border-info{
    display: none;
  }
  .w09f200_avatar {
    max-width: 100%;

    .img-center-reponsive {
      margin: 0 auto;
    }
  }
}

@media (max-height: 768px) {
  #Template-Mail-Body {
    height: 420px !important;
  }
}

.W39F3011Goal {
  .divGoal {
      &:hover {
          background-color: #a2cfff !important;
          & #iconDelete {
              visibility: visible !important;
              cursor: pointer !important;
              z-index: 1500;
          }
          .divGoalChild {
              background-color: #a2cfff !important;
          }
      }
  }
}

@keyframes blinking{
    50% {color: $dangerColor;}
}
