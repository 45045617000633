@font-face {
  font-family: 'FontAwesome';
  src: url("../assets/fonts/FontAwesome.ttf") format("truetype"); }

@font-face {
  font-family: 'Futura-Condensed-Extra-Bold';
  src: url("../assets/fonts/Futura-Condensed-Extra-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'open-sans-bold';
  src: url("../assets/fonts/open-sans.bold.ttf") format("truetype"); }

@font-face {
  font-family: 'open-sans-extrabold';
  src: url("../assets/fonts/open-sans.extrabold.ttf") format("truetype"); }

@font-face {
  font-family: 'open-sans-light';
  src: url("../assets/fonts/open-sans.light.ttf") format("truetype"); }

@font-face {
  font-family: 'open-sans-light-italic';
  src: url("../assets/fonts/open-sans.light-italic.ttf") format("truetype"); }

@font-face {
  font-family: 'open-sans-regular';
  src: url("../assets/fonts/open-sans.regular.ttf") format("truetype"); }

@font-face {
  font-family: 'open-sans-semibold';
  src: url("../assets/fonts/open-sans.semibold.ttf") format("truetype"); }

//-----------SpaceMono

@font-face {
  font-family: 'SpaceMono-Regular';
  src: url("../assets/fonts/SpaceMono-Regular.ttf") format("truetype"); }

//-----------Avenir

@font-face {
  font-family: 'Avenir-Black';
  src: url("../assets/fonts/Avenir-Black.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-Book';
  src: url("../assets/fonts/Avenir-Book.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-BlackOblique';
  src: url("../assets/fonts/Avenir-BlackOblique.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-Heavy';
  src: url("../assets/fonts/Avenir-Heavy.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-HeavyOblique';
  src: url("../assets/fonts/Avenir-HeavyOblique.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-Light';
  src: url("../assets/fonts/Avenir-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-LightOblique';
  src: url("../assets/fonts/Avenir-LightOblique.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-Medium';
  src: url("../assets/fonts/Avenir-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-MediumOblique';
  src: url("../assets/fonts/Avenir-MediumOblique.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-Oblique';
  src: url("../assets/fonts/Avenir-Oblique.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-Roman';
  src: url("../assets/fonts/Avenir-Roman.ttf") format("truetype"); }

//-----------------SourceSansPro

@font-face {
  font-family: 'SourceSansPro-Black';
  src: url("../assets/fonts/SourceSansPro-Black.ttf") format("truetype"); }

@font-face {
  font-family: 'SourceSansPro-BlackItalic';
  src: url("../assets/fonts/SourceSansPro-BlackItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'SourceSansPro-Bold';
  src: url("../assets/fonts/SourceSansPro-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'SourceSansPro-BoldItalic';
  src: url("../assets/fonts/SourceSansPro-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'SourceSansPro-ExtraLight';
  src: url("../assets/fonts/SourceSansPro-ExtraLight.ttf") format("truetype"); }

@font-face {
  font-family: 'SourceSansPro-ExtraLightItalic';
  src: url("../assets/fonts/SourceSansPro-ExtraLightItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'SourceSansPro-Italic';
  src: url("../assets/fonts/SourceSansPro-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'SourceSansPro-Light';
  src: url("../assets/fonts/SourceSansPro-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'SourceSansPro-LightItalic';
  src: url("../assets/fonts/SourceSansPro-LightItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'SourceSansPro-Regular';
  src: url("../assets/fonts/SourceSansPro-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'SourceSansPro-SemiBold';
  src: url("../assets/fonts/SourceSansPro-SemiBold.ttf") format("truetype"); }

@font-face {
  font-family: 'SourceSansPro-SemiBoldItalic';
  src: url("../assets/fonts/SourceSansPro-SemiBoldItalic.ttf") format("truetype"); }

//-------Roboto

@font-face {
  font-family: 'Roboto-Light';
  src: url("../assets/fonts/Roboto-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-Light';
  src: url("../assets/fonts/Roboto-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-Black';
  src: url("../assets/fonts/Roboto-Black.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url("../assets/fonts/Roboto-BlackItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-Bold';
  src: url("../assets/fonts/Roboto-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url("../assets/fonts/Roboto-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-Italic';
  src: url("../assets/fonts/Roboto-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url("../assets/fonts/Roboto-LightItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-Medium';
  src: url("../assets/fonts/Roboto-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url("../assets/fonts/Roboto-MediumItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-Regular';
  src: url("../assets/fonts/Roboto-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-Thin';
  src: url("../assets/fonts/Roboto-Thin.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url("../assets/fonts/Roboto-ThinItalic.ttf") format("truetype"); }

//--Muli
@font-face {
  font-family: 'Muli-Bold';
  src: url("./fonts/Muli/Muli-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Muli';
  src: url("./fonts/Muli/Muli-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'Muli-Light';
  src: url("./fonts/Muli/Muli-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'Muli-Black';
  src: url("./fonts/Muli/Muli-Black.ttf") format("truetype"); }

@font-face {
  font-family: 'Muli-Italic';
  src: url("./fonts/Muli/Muli-Italic.ttf") format("truetype"); }

//Material UI font
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@font-face {
  font-family: 'RobotoFallback';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(fonts/Roboto-300.woff2) format("woff2"), url(fonts/Roboto-300.woff) format("woff"), url(fonts/Roboto-300.ttf) format("truetype"); }

@font-face {
  font-family: 'RobotoFallback';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(fonts/Roboto-400.woff2) format("woff2"), url(fonts/Roboto-400.woff) format("woff"), url(fonts/Roboto-400.ttf) format("truetype"); }

@font-face {
  font-family: 'RobotoFallback';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(fonts/Roboto-500.woff2) format("woff2"), url(fonts/Roboto-500.woff) format("woff"), url(fonts/Roboto-500.ttf) format("truetype"); }

@font-face {
  font-family: 'RobotoFallback';
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(fonts/Roboto-700.woff2) format("woff2"), url(fonts/Roboto-700.woff) format("woff"), url(fonts/Roboto-700.ttf) format("truetype"); }

//fonts
@font-face {
  font-family: 'Avenir-Black';
  src: url("../assets/fonts/Avenir-Black.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-Book';
  src: url("../assets/fonts/Avenir-Book.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-BlackOblique';
  src: url("../assets/fonts/Avenir-BlackOblique.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-Heavy';
  src: url("../assets/fonts/Avenir-Heavy.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-HeavyOblique';
  src: url("../assets/fonts/Avenir-HeavyOblique.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-Light';
  src: url("../assets/fonts/Avenir-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-LightOblique';
  src: url("../assets/fonts/Avenir-LightOblique.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-Medium';
  src: url("../assets/fonts/Avenir-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-MediumOblique';
  src: url("../assets/fonts/Avenir-MediumOblique.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-Oblique';
  src: url("../assets/fonts/Avenir-Oblique.ttf") format("truetype"); }

@font-face {
  font-family: 'Avenir-Roman';
  src: url("../assets/fonts/Avenir-Roman.ttf") format("truetype"); }
